.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-dots {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.dot {
    aspect-ratio: 1/1;
    width: 1rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation-name: dots;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

.loading-text {
    font-size: 1.5rem; 
    font-weight: 700;
    text-transform: uppercase;
}

.loading-dots .dot:nth-child(2) {
    animation-delay: 0.4s;
}

.loading-dots .dot:nth-child(3) {
    animation-delay: 0.8s;
}

.skel-img-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: clamp(100px, 20vw, 400px);
    gap: 0.75rem;
}

.skel-img-container .skel-img:nth-child(1) {
    grid-column: span 6;
}

.skel-img-container .skel-img:nth-child(2), 
.skel-img-container .skel-img:nth-child(3){
    grid-column: span 3;
}

.skel-img-container .skel-img:nth-child(n+4) {
    grid-column: span 2;
}

.skel-img {
    border-radius: 0.25rem;
}

.skel-res-link {
    width: 60%;
    height: 1.25rem;
    margin-top: 0.5rem; 
    margin-bottom: 0.5rem; 
    border-radius: 0.2rem;
}

@media only screen and (max-width: 600px) {

    .skel-img-container {
        gap: 0.25rem;
        grid-auto-rows: 30vw;
    }

}

.skeleton {
    background-color: rgba(255, 255, 255, 0.15);
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%, 
        rgba(255, 255, 255, 0) 60% 

    ) rgba(255, 255, 255, 0.15);
    background-size: 200% 100%; 
    background-position-x: 180%;
    animation: 2s skeleton-anim ease-in-out infinite;
}

@keyframes skeleton-anim {
    to {
        background-position-x: -20%;
    }
}

@keyframes dots {
    50%
    {
        background-color: rgba(255, 255, 255, 0.5);
    }
}
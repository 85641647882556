.box{
    font-family: "Montserrat", sans-serif;
    text-align: center;
    display: flex; 
    flex-direction: column;

    background: rgba( 255, 255, 255, 0.07);
    box-shadow: 0 4px 8px 0 rgba(3, 6, 46, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 5px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.box .title{
    font-weight: 700;
    text-transform: uppercase;
    margin-top: auto;
}

.box .desc{
    font-weight: 400;
    font-size: clamp(5px, 1vw, 50px);
    white-space: pre-line;
}

.top {
    height: 25%;
    display: flex; 
    flex-direction: column;
}

.middle{
    height: 30%;
    display: flex;
    justify-content: center;
}

.bottom {
    padding-top: 10%;
}


 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .box{
        width: 10rem;
        height: 10rem;
        border-radius: 2rem;
    }

    .box .title{
        font-size: 1rem;
    }

    .box .icon{
        font-size: 1.75rem;
        text-align: center;
    }

    .box .desc{
        font-size: .65rem;
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {
    
    .box{
        width: 27vw;
        height: 27vw;
        border-radius: 5vw;
    }

    .box .title{
        font-size: 3.25cqw;
    }

    .box .icon{
        font-size: 5cqw;
    }

    .box .desc{
        font-size: 1.7cqw;
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

    

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

    

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

    .box{
        width: 15.89vw;
        height: 15.89vw;
        border-radius: 3vw;
    }

    .box .title{
        font-size: clamp(10px, 1.75vw, 100px);
        margin-top: auto;
    }

    .box .icon{
        font-size: clamp(10px, 3.646vw, 100px);
    }

    .box .desc{
        font-size: clamp(0.5rem, 0.85vw, 6rem);
    }

 }
.scaler-transformer {
    transition: transform 250ms ease-in-out;
}

.scaler-transformer.dragging {
    transition: none;
}

.img-parent {
    overflow: hidden;
}

.blurred-img {
    width: 100%; 
    height: 100%;
    background-size:cover;
    background-position: center;
    filter: blur(8px);
}

.blurred-img::before {
    content: '';
    position: absolute; 
    inset: 0; 
    opacity: 0;
    background-color: white;
}

.blurred-img img {
    opacity: 0; 
}

.blurred-img.loaded img {
    opacity: 1;
}

.blurred-img.loaded::before {
    animation: none;
    content: none;
}

.blurred-img.loaded {
    filter: blur(0px);
}
h1, h2, h3, p, .about-email-link{
    color: white !important;
}

h1, h2, h3 {
    font-family: "Montserrat", sans-serif;
}

h1{
    text-transform: uppercase !important;
}

body {
    background-color: #141516 !important;
}

.img-section{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100svh;
}

.section-overlay{
    background-color: rgba(23, 24, 29, 0.4);
    width: 100%; 
    height: 100%;
}

.home-section{
    background-image: url(../assets/images/4.webp);
    background-position-y: center;
    text-align: center;
}

.about-section .section-overlay{
    display: flex;
    text-align: center;
}

.portfolio-section{
    padding-bottom: 5rem;
    text-align: center;
}

.img-section h1, .img-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    z-index: 1;
}

.descr-title{
    font-weight: 300;
}

.profile-img{
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 15%;
    opacity: 100%;
    overflow: hidden;
}

.profile-img .blured-img {
    background-position: 50% 10%;
}

.thick{
    font-weight: 900;
}

.thin{
    font-weight: 300;
}

.about-icon{
    font-size: 3.5vw;
}

.about-icons{
    display: flex;
    gap: 1vw;
}

.about-email-link{
    font-size: 1vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-decoration: none;
}

.about-edu{
    margin-top: 2vh;
    margin-bottom: 4vh;
}

.edu-box-wrapper{
    display: flex;
    gap: 1vw;
}

.portfolio-buttons{
    display: grid;
    justify-content: center;
    padding-left: 5rem;
    padding-right: 5rem;
}

 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .name-title{
        font-size: clamp(20px, 12vw, 75px);
    }

    .descr-title{
        font-size: clamp(15px, 4.25vw, 22px);
    }

    .home-section{
        background-position-x: 19.5%;
    }

    .btn-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5.5vh;
    }

    .about-section .section-overlay{
        flex-direction: column;
        align-items: center;
        background: rgb(20,21,22);
        background: linear-gradient(0deg, rgba(20,21,22,1) 0%, rgba(20,21,22,0) 100%);
    }

    .profile-img{
        width: 48vw; 
        aspect-ratio: 1/1;
    }

    .profile-div{
        margin-top: 15vw;
        display: flex;
        align-items: flex-end;
    }

    .portfolio-title {
        margin-top: 4rem;
        font-size: 11vw;
        margin-bottom: 1rem;
    }

    .about-section{
        background-image: url(../assets/images/Spiral-center-crop-01.webp); 
        background-position-x: center;
        background-position-y: 0%;
        min-height: 780px;
    }

    .about-section .section-overlay{
        flex-direction: column;
        align-items: center;
    }

    .about-title.t1{
        font-size: 2.25rem;
        margin-top: 2rem;
    }

    .about-p{
        font-size: 1rem;
        text-align: left;
        padding-left: 12%;
        padding-right: 8%;
    }

    .about-title.t2{
        font-size: 1.5rem;
    }

    .about-icons{
        justify-content: center;
        gap: 4rem;
    }

    .about-icon{
        font-size: 2.75rem;
    }

    .about-edu{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-links{
        margin-top: auto;
        margin-bottom: 8%;
    }

    .about-desc{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .knowledge-section{
        display: flex; 
        flex-direction: column;
        align-items: center;
        padding-top: 4rem;
    }

    .portfolio-buttons{
        gap: 10px;
        grid-template-columns: auto;
    }

    .img-section {
        min-height: 780px;
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {

    .name-title{
        font-size: clamp(10px, 4.9vw, 55px);
        margin-bottom: 1.25vh;
    }

    .descr-title{
        font-size: clamp(8px, 3vw, 42px);
    }

    .home-section{
        background-position-x: 78%;
    }

    .btn-container{
        margin-top: 5.5vw;
        display: flex;
        justify-content: center;
        gap: 2vw;
    }

    .about-title.t1{
        font-size: clamp(10px, 3.33vw, 100px);
    }

    .about-section{
        background-image: url(../assets/images/Spiral-center-crop-01.webp); 
        background-position-x: center;
    }

    .about-desc{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-section .section-overlay{
        flex-direction: column;
        align-items: center;
        background: rgb(20,21,22);
        background: linear-gradient(0deg, rgba(20,21,22,1) 0%, rgba(20,21,22,0) 100%);
    }

    .profile-img{
        width: 282px; 
        height: 282px; 
    }

    .profile-div{
        margin-top: 8vw;
        margin-bottom: 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-title.t1{
        font-size: 8.33vw;
    }

    .about-p{
        font-size: 2.9vw;
        text-align: left;
        margin-left: 8vw;
    }

    .about-title.t2{
        font-size: 2.5rem;
    }

    .about-icons{
        justify-content: center;
        gap: 1.75rem;
    }

    .about-icon{
        font-size: 2.5rem;
    }

    .about-email-link{
        font-size: 1.5rem;
    }

    .knowledge-section .knowledge-items-container{
        display: grid; 
        grid-template-columns: auto auto auto;
        justify-content: center;
        gap: 7%;
    }

    .knowledge-section{
        padding: 10%;
        padding-top: 8rem;
        padding-bottom: 10rem;
    }

    .knowledge-tite{
        margin-bottom: 4rem;
        font-size: 45px;
    }

    .portfolio-title{
        font-size: 4rem;
    }

    .portfolio-buttons{
        gap: 8px;
        grid-template-columns: auto auto auto;
    }

    .img-section {
        min-height: 900px;
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .img-section {
        min-height: 1050px;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

    .btn-container{
        margin-top: 3vw;
    }

    .img-section {
        min-height: 1200px;
    }

    .knowledge-section .knowledge-items-container{
        gap: 10%;
    }

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    
    .img-section {
        min-height: calc(100vw/ 2.4);
    }

    .name-title{
        font-size: clamp(10px, 3.25vw, 90px);
        margin-bottom: 0;
    }

    .descr-title{
        font-size: clamp(10px, 2.025vw, 60px);
    }

    .home-section{
        background-position-x: auto;
    }

    .about-title.t1{
        font-size: clamp(10px, 5vw, 200px);
    }

    .about-section{
        background-image: url(../assets/images/Spiral-cropped-01.webp); 
        background-position-y: center;
        background-position-x: 23%;
    }

    .about-desc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: start;
        flex: 1;
        margin-left: 5vw;
    
        flex-grow: 0;
    }

    .about-section .section-overlay{
        flex-direction: row;
        background: rgb(20,21,22);
        background: linear-gradient(270deg, rgba(20,21,22,1) 0%, rgba(20,21,22,0) 100%);
    }

    .profile-img{
        width: 30vw; 
        height: 30vw; 
    }

    .profile-div{
        display: flex;
        justify-content: end;
        align-items: center;
        width: 40vw;
    
        flex-shrink: 0;
    }

    .about-p{
        font-size: clamp(10px, 1.66vw, 48px);
        margin: 0;
    }

    .about-title.t2{
        font-size: clamp(10px, 2.1vw, 100px);
        margin-bottom: 1rem;
    }

    .about-links{
        display: flex; 
        justify-content: flex-start;
        align-items: center;
        gap: 1vw;
    }

    .about-icons{
        gap: 1vw;
    }

    .about-email-link{
        font-size: 1vw;
    }

    .knowledge-section .knowledge-items-container{
        gap: 4%;
    }

    .portfolio-title{
        margin-bottom: 2rem;
    }

    .portfolio-buttons{
        gap: 10px;
        margin-bottom: 6rem;
    }

 }
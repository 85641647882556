.img{
    border-radius: 0.25rem;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.blured-img {
    width: 100%; 
    height: 100%;
    background-size:cover;
    background-position: center;
    filter: blur(8px);
}

.blured-img::before {
    content: '';
    position: absolute; 
    inset: 0; 
    opacity: 0;
    background-color: white;
}

.blured-img img {
    opacity: 0; 
    transition: opacity 0.25s ease-in-out;
}

.blured-img.loaded img {
    opacity: 1;
}

.blured-img.loaded::before {
    animation: none;
    content: none;
}

.blured-img.loaded {
    filter: blur(0px);
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}

.thumb-title{
    font-weight: 600;
    transform: translateY(20px);
    transition: transform 0.3s ease;
}

.overlay{
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease;

    position: absolute;
    top: 0; 
    left: 0;
    z-index: 1;
}

.overlay:hover{
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    cursor: pointer;
}

.overlay:hover .thumb-title{
    transform: translateY(0px);
}

/* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

/* Extra small devices (phones, 600px and down) */     
@media only screen and (max-width: 600px) {
    
    /* Regardless of if the grid is just one column the sizes of the thumbnails will be the same */
    .img, .skel-proj-thumb {
        aspect-ratio: 1/1;
        width: clamp(1rem, 71.944vw + 1rem, 20rem);
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) {
    .single, .skel-proj-thumb{
        width: clamp(2rem, 83.85416vw + 1rem, 41.25rem);
        aspect-ratio: 33/13;
    }

    .multi {
        width: clamp(16.8rem, 39.5833vw + 1rem, 20rem); 
        aspect-ratio: 16/9;
    }

    .content-grid .thumb-title {
        font-size: clamp(0.5rem, 0.75vw + 1rem, 2rem);
    }
}
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .single, .skel-proj-thumb{
        width: clamp(2rem, 83.5416vw + 1rem, 101.25rem);
        aspect-ratio: 81/22;
    }

    .multi {
        width: clamp(10rem, 26.6666vw + 1rem, 33rem); 
        aspect-ratio: 16/9;
    }

    .thumb-title {
        font-size: 4rem;
    }

    .content-grid .thumb-title {
        font-size: clamp(2rem, 1vw + 1rem, 3.5rem);
    }
}
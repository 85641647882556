.gallery {
    display: grid;
    gap: 0.75rem; 
    width: 100%;
    margin-bottom: 2rem;
}

.gallery .img {
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: all 500ms;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
}

.img-el {
    object-fit: cover;
    width: 100%; 
    height: 100%;
}

.gallery .img:hover {
    transform: translateY(-3px) scale(1.1);
    cursor: pointer;
    z-index: 1;
}

.gallery .tall {
    grid-row: span 2 / auto;
}

.gallery .wide {
    grid-column: span 2 / auto;
}

.preview-overlay {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.preview-content {
    position: relative; 
    width: 100%;
    max-height: 80%; 
    display: flex;
    align-items: center;
    overflow: hidden;
}

.preview-content .preview-img, 
.preview-content img {
    max-width: 75vw; 
    max-height: 75vh;
    object-fit: contain;
}

.img-desc-container,
.next-prev-container, 
.close-btn {
    position: absolute;
    z-index: 1001;
}

.close-btn,
.next-btn, 
.previous-btn {
    color: white;
    background: none; 
    width: 3rem; 
    font-size: 1.25rem;
    border: none;
    text-shadow: black 1px, 1px;
}

.img-desc-container {
    bottom: 2rem;
    display: flex;
    justify-content: center;
}

.next-prev-container {
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}

.img-btn-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 1.125rem;
    width: 80vw;
}

.img-btn-item {
    border: solid 1px white;
    background: rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    flex: 1;
    transition: background 200ms ease-in-out;
}

.img-btn-cur{
    background: white;
}

.img-btn-item:not(.img-btn-cur):hover {
    border: solid 1px white;
    background: rgba( 255, 255, 255, 0.25);
}

.img-btn-container .img-btn-item:first-child {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.img-btn-container .img-btn-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.preview-overlay, 
.preview-content {
    user-select: none;
}

.no-scroll {
    overflow: hidden !important;
}

.preview-carousel-container {
    display: flex;
}

.carousel-slide {
    width: 100vw;
    display: flex; 
    justify-content: center;
    align-items: center;
}

/* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

/* Extra small devices (phones, 600px and down) */     
@media only screen and (max-width: 600px) {
    .col-grid-3 {
        grid-template-columns: repeat(3, minmax(75px, 1fr));
        grid-auto-rows: 120px;
    }

    .col-grid-2 {
        grid-template-columns: repeat(2, minmax(75px, 1fr));
        grid-auto-rows: 120px;
    }

    .col-grid-1 {
        grid-template-columns: minmax(75px, 1fr);
        grid-auto-rows: 120px;
    }

    .close-btn {
        top: 2rem; 
        right: 2rem; 
    }

    .gallery {
        width: 100vw;
        gap: 0.25rem;
        padding-left: 1vw;
        padding-right: 1vw;
        margin-bottom: 0.25rem;
    }

    .close-btn,
    .next-btn, 
    .previous-btn {
        display: none;
    }

    .img-desc-container {
        bottom: 2rem;
    }
    
    .next-prev-container {
        bottom: 1.25rem;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) {

    .col-grid-3 {
        grid-template-columns: repeat(3, minmax(130px, 1fr));
        grid-auto-rows: 130px;
    }

    .col-grid-2 {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        grid-auto-rows: 18.23vw;
    }

    .col-grid-1 {
        grid-template-columns: minmax(300px, 1fr);
        grid-auto-rows: 25vw;
    }

    .close-btn {
        top: 2rem; 
        right: 2rem; 
    }

    .close-btn,
    .next-btn, 
    .previous-btn {
        display: none;
    }

    .img-desc-container {
        bottom: 0.65rem;
    }
    
    .next-prev-container {
        bottom: 0.5rem;
    }

}
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (min-height: 400px){
    .img-desc-container {
        bottom: 4rem;
    }
    
    .next-prev-container {
        bottom: 1.5rem;
    }

}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .close-btn,
    .next-btn, 
    .previous-btn {
        display: block;
    }

    .img-desc-container {
        bottom: 3rem;
    }
}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .col-grid-3 {
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        grid-auto-rows: 15rem;
    }
    
    .col-grid-2 {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        grid-auto-rows: 20rem;
    }
    
    .col-grid-1 {
        grid-template-columns: minmax(300px, 1fr);
        grid-auto-rows: 30rem;
    }

    .close-btn {
        top: 5rem; 
        right: 6rem; 
    }

    .img-btn-container {
        width: 50rem;
    }

}
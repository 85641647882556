.btn-txt{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .btn-txt{
        font-size: 5.5vw;
    }

    .btn-element{
        width: 40.5vw;
        margin-top: 2vh;
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {
    
    .btn-txt{
        font-size: 2.5vw;
    }

    .btn-element{
        width: 19vw;

        /*
        margin-left: 1.3vw; 
        margin-right: 1.3vw;
        */
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    
    .btn-txt{
        font-size: 1.8vw;
    }

    .btn-element{
        width: 12.75vw;

        /*
        margin-left: 1.3vw; 
        margin-right: 1.3vw;
        */
    }

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

 }
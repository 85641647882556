.port-btn {
    font-family: "Montserrat", sans-serif;
    color: white; 
    text-align: center;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    user-select: none;

    position: relative;
    background: rgba(45,45,45,1);
    border: none;
    overflow: hidden;
}

.port-btn::before {
    content: '';
    position: absolute; 
    background: radial-gradient(rgba(127, 255, 212, 0.6), transparent, transparent);
    width: 200%; 
    height: 200%;
    opacity: 0;
    transition: 0.5s, top 0, left 0;
    top: var(--y); 
    left: var(--x);
    transform: translate(-50%, -50%);
}

.port-btn:hover::before {
    opacity: 1;
}

.port-btn:hover::after {
    content: '';
    position: absolute; 
    inset: 2px; 
    background: rgba(45, 45, 45, 0.9);
    border-radius: inherit;
}

.port-btn .btn-icon, 
.port-btn .btn-title {
    z-index: 1;
}

.btn-title{
    font-weight: 500;
    margin-top: 10%;
    transition: font-weight 0.25s ease-in-out, font-size 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.btn-icon{
    margin-top: 25%;
    transition: transform 0.25s ease-in-out;
}

/*
.port-btn:hover{
    background: linear-gradient(0deg, rgba(255,255,255,0.35) 0%, rgba(255,255,255,0.1) 100%);
}*/

.port-btn:hover .btn-title {
    transition: ease-in-out 0.25s;
    font-weight: 900;
    transform: translateY(-80%);
}

 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .port-btn{
        aspect-ratio: 2.75/1;
        width: 17.5rem;
        border-radius: 10px;
    }

    .btn-title{
        font-size: 1rem;
        margin-top: 0;
    }
    
    .btn-icon{
        font-size: 1.75rem;
        margin-top: 5%;
    }

    .port-btn:hover .btn-title {
        transition: ease-in-out 0.25s;
        font-weight: 900;
        transform: translateY(0%);
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {
    
    .port-btn{
        width: clamp(10.9375rem, 24.6vw + 1rem, 12.8125rem);
        border-radius: 40px;
    }

    .btn-title{
        font-size: clamp(0.25rem, 0.521vw + 1rem, 1.25rem);
    }
    
    .btn-icon{
        font-size: clamp(2rem, 5.47vw + 1rem, 3.625rem);
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

    .port-btn{
        width: 360px;
        border-radius: 58px;
    }

    .btn-title{
        font-size: 2.25rem;
    }
    
    .btn-icon{
        font-size: 6rem;
    }

 }
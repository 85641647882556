.knowledge-wrapper{
    color: white; 
    font-family: "Montserrat", sans-serif;
}

.title{
    font-weight: 700;
}

 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .knowledge-wrapper{
        width: 16rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex; 
        text-align: left;
        align-items: center;
        justify-content: flex-end;
    }

    .title{
        font-size: 1rem;
    }
    
    .desc{
        font-size: 1rem;
    }

    .icon{
        font-size: 2rem;
        width: 3rem;
        text-align: center;
    }

    .mobile-wrapper{
        margin-left: 1.25rem;
        width: 12rem;
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {
    
    .knowledge-wrapper{
        width: 12rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
    .icon{
        font-size: 2rem;
    }
    
    .title{
        font-size: 1rem;
    }
    
    .desc{
        font-size: 1rem;
    }

 }

 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

    .knowledge-wrapper{
        width: clamp(8rem, 20.83vw + 1rem, 26rem);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    
    .icon{
        font-size: clamp(1.5rem, 2.5vw + 1rem, 3rem);
    }
    
    .title{
        font-size: 2rem;
    }
    
    .desc{
        font-size: 1.5rem;
        margin-top: 1.5rem;
    }

 }
.resume-bg {
    background-image: url("../assets/images/resume-bg.webp");
    background-size: cover;
    background-position: top center;
    font-family: "Montserrat", sans-serif;
}

.content-div {
    display: flex; 
    justify-content: center;
    align-items: center;
}

.content-div{
    height: 100vh;
}

.resume-prev {
    background: rgba( 0, 0, 0, 0.75 );
    width:100vw;
 }

.resume-list-container {
    background: rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
}

#resumes-title {
    font-weight: 800;
    text-align: center;
}

.resume-link-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.resume-link-txt p {
    margin: 0;
    font-weight: 500;
    transition: 0.3s font-weight ease;
}

.resume-items-container hr {
    border-top: solid 2px;
    border-color: white;
}

.resume-link-txt:hover p{
    font-weight: 700;
}

.download-btn {
    background: none;
    border: white solid 1px;
    border-radius: 2rem;
    color: white;
    transition: 0.4s background ease;
}

.download-btn:hover {
    background: white; 
    color: black;
}

.preview-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.res-prev-title {
    font-weight: 900;
    text-transform: uppercase;
}

.close-title-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: white;
}

.res-close-icon{
    cursor: pointer;
}

.resume-prev {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.close-title-container p {
    margin: 0;
}

.skeleton-resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    align-items: center;
    background: rgba( 255, 255, 255, 0.15 );
    border-radius: 0.25rem 0.25rem 0 0;
    border:rgba( 255, 255, 255, 0.3 ) solid 1px;
    border-bottom: 0;
    backdrop-filter: blur(10px);
    padding: 4rem;
    padding-bottom: 6rem;
    overflow: hidden;
}

.skel-res-head {
    width: 33%; 
    margin-top: 5%;
}

.skel-res-skills, .skel-res-exp, .skel-res-head {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.skel-res-txt{
    height: 1rem;
    border-radius: 2px;
}

.skel-res-skills {
    width: 50%;
    align-self: flex-start;
}

.skel-res-exp {
    width: 100%;
}

.skel-res-exp .skel-res-txt:nth-child(1) {
    width: 25%;
}

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {

    .content-div {
        min-height: 540px;
        padding-top: 5rem;
    }

    .resume-prev {
        height: calc(100vh - 5rem);
        min-height: calc(540px - 5rem);
    }

    .resume-list-container {
        width: 20rem;
        padding: 1.75rem;
        border-radius: 0.75rem;
    }

    #resumes-title {
        font-size: 2.25rem;
        margin-bottom: 0.75rem;
    }

    .resume-title-container {
        margin-bottom: 4rem;
    }

    .resume-title-container p {
        font-size: 0.7rem;
    }

    .preview-controls {
        margin: 1rem 14px 2px 14px;
    }

    .close-title-container {
        gap: 1rem;
        font-size: 14px;
    }

    .resume-doc, .skeleton-resume {
        height: 92%;
    }

    .cross, .download-btn {
        display: none;
    }

    .mobile-dl-btn{
        border: none;
        background: none;
        color: white;
    }

    .skeleton-resume {
        gap: 2.5rem;
    }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {

    .arrow, .mobile-dl-btn{
        display: none;
    }

    .content-div {
        min-height: 675px;
        padding-top: 6rem;
    }

    .skeleton-resume {
        gap: 3.5rem;
        padding-top: 8rem;
    }

    .resume-prev {
        height: calc(100vh - 6rem);
        min-height: calc(675px - 6rem);
    }

    .resume-list-container {
        width: 30rem;
        padding: 3rem;
        border-radius: 1rem;
    }

    #resumes-title {
        font-size: 4rem;
        margin-bottom: 0.5rem;
    }

    .resume-title-container {
        margin-bottom: 3rem;
    }

    .resume-items-container {
        font-size: 1.5rem;
    }

    .resume-prev {
        height: calc(100vh - 6rem);
    }

    .preview-controls {
        margin: 1.75rem 2.5rem 1rem 2.5rem;
    }

    .download-btn {
        font-size: 1rem;
        padding: 0.25rem 1rem 0.25rem 1rem;
    }

    .close-title-container {
        gap: 1rem;
        font-size: 1rem;
    }

    .resume-doc, .skeleton-resume {
        width: clamp(35rem, 65svw, 50rem);
        height: 85%;
        align-self: center;
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

    .content-div {
        min-height: 830px;
        padding-top: 7.5rem;
    }

    .resume-list-container {
        width: 35rem;
        padding: 4rem;
    }

    #resumes-title {
        font-size: 5.25rem;
        margin-bottom: 0.5rem;
    }

    .resume-title-container {
        margin-bottom: 6rem;
    }

    .resume-prev {
        height: calc(100vh - 7.5rem);
        min-height: calc(830px - 7.5rem);
    }

    .preview-controls {
        margin: 2.5rem 4rem 1rem 4rem;
    }

    .download-btn {
        font-size: 1.5rem;
        padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    }

    .close-title-container {
        gap: 1rem;
        font-size: 1.5rem;
    }

    .resume-doc, .skeleton-resume {
        width: 55rem;
        height: 85%;
        align-self: center;
    }

    .skeleton-resume {
        gap: 4rem;
    }

 } 
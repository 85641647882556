.footer {
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    border-top: 1px solid rgba( 255, 255, 255, 0.18 );

    width: 100vw;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
}

.footer-icons {
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    position: relative;
}

.foot-top, .foot-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

#foot-email {
    font-size: 1rem;
    text-decoration: none;
    color: white;
}

#foot-logo {
    position: absolute;
    width: 1.75rem;
    left: 50%;
    transform: translateX(-50%);
}

#site-map-title {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    margin: 0;
}

.site-map-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    text-transform: uppercase;
}

.footer-nav {
    font-size: 14px; 
    color: white; 
    text-decoration: none;
}

/* Extra small devices (phones, 600px and down) */     
@media only screen and (max-width: 600px) {

    .footer {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    #foot-logo {
        display: none;
    }
    
    .foot-top, .foot-bottom {
        flex-direction: column;
        justify-content: center;
    }

    .footer-icons{
        font-size: 2.25rem;
        gap: 1.5rem;
    }

    #foot-email {
        font-size: 12px;
    }

    .site-map-links {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.6rem;
        margin-top: 1rem;
    }

    .footer-nav:only-child, 
    .foot-nav:first-child:nth-last-child(3) {
        flex-basis: 100%;
    }

    .footer-nav {
        font-size: 11px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) {

    .site-map-links {
        gap: clamp(0.5rem, 2.3vw, 1.5rem);
    }

    #foot-email, #site-map-title, .footer-nav {
        font-size: clamp(10px, 1.75vw, 12px);
    }

    .footer-icons {
        font-size: clamp(1rem, 3.125vw, 1.5rem);
        gap: 1.5625vw;
    }

}
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #foot-email, #site-map-title, .footer-nav {
        font-size: 12px;
    }

    .footer-icons {
        font-size: 1.5rem;
        gap: 0.75rem;
    }

    .site-map-links {
        gap: 1.5rem;
    }

    .footer {
        padding-top: 1.5rem;
        padding-bottom: 2.25rem;
    }

    .foot-mid {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    #foot-email, #site-map-title {
        font-size: 1rem;
    }

    .footer-nav {
        font-size: 14px;
    }

    .footer {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
    }

    .foot-mid {
        margin-bottom: 2.25rem;
        margin-top: 2.25rem;
    }

    .site-map-links {
        gap: 2.5rem;
    }

}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
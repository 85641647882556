@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.nav-bar {

    background: rgba( 255, 255, 255, 0.08 );
    border-bottom: 1px solid rgba( 255, 255, 255, 0.18 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    
    width: 100%;
    height: 8rem;
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
}

.nav-title{
    color: white; 
    font-family: 'Montserrat', sans-serif;
    font-size: 1.35rem;
    font-weight: 900;
    margin-right: auto;
    margin-left: 4rem;
    text-decoration: none;
}

.nav-page{
    color: white; 
    font-family: 'Montserrat', sans-serif;
    font-size: 1.35rem; 
    font-weight: 600;
    text-decoration: none;
    align-items: center;
    text-transform: uppercase;
    margin-left: 3rem;
    position: relative;
    overflow: hidden;
}

.nav-page::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease;
}

.nav-page:hover::after,
.nav-page.active-underline::after {
    width: 100%;
}

.nav-links {
    margin-right: 4rem;
}

.nav-btn{
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    border: none; 
    color: white;
}

.menu-info{
    display: none;
}

 /* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

 /* Extra small devices (phones, 600px and down) */     
 @media only screen and (max-width: 600px) {
    .nav-btn{
        display: flex;
        opacity: 1;
    }

    .menu-info{
        display: block;
    }

    .menu-info-icon{
        font-size: 3rem;
    }

    .menu-email{
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 0.9rem;
        color: white; 

    }

    .nav-btn-burger{
        margin-left: auto;
        margin-right: 1.5rem;
    }

    .nav-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin: 0;
    }

    .nav-links {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #141516;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .nav-links.active-menu{
        display: flex; 
        opacity: 1;
        border-bottom: none;
    }

    .nav-bar:has(.active-menu) {
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }

    .nav-bar {
        height: 5rem;
    }

    .nav-page{
        font-weight: 700;
        font-size: clamp(20px, 2.75vh, 28px);
        margin: 0;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 601px) {
    .nav-title{
        font-size: clamp(6px, 2.1vw, 1.5rem);
        margin-left: 2rem;
    }

    .nav-bar{
        height: 6rem;
    }

    .nav-links a{
        font-size: clamp(6px, 2.1vw, 1.5rem);
        margin: 0.5rem;
    }

    .nav-links{
        margin-right: 1rem;
        margin-right: 2rem;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .nav-bar {
        width: 100%;
        height: 7.5rem;
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
    }
    
    .nav-title{
        font-size: 1.25rem; 
        font-weight: 900;
        margin-right: auto;
        margin-left: 4rem;
        text-decoration: none;
    }
    
    .nav-links a{
        font-size: 1.25rem; 
        font-weight: 600;
        align-items: center;
        margin-left: 3rem;
    }
    
    .nav-links {
        margin-right: 4rem;
    }
 } 
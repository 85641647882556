.background {
    background: #141516;
    width: 100vw;
}

.proj-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.proj-title, .skeleton-proj-title {
    font-weight: 900;
    margin-top: 6rem;
}

.date, .skeleton-proj-date {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.proj-img {
    width: 10rem;
    background-size: cover;
}

.desc-container {
    margin-top: 1.5rem;
}

.content-container {
    margin: 0 auto; 
    padding: 1rem;
}

.proj-about-title{
    font-size: 3rem;
    margin-top: 4rem;
}

.md-desc p, 
.md-desc li, 
.md-desc a, 
.md-desc hr {
    color: white;
}

.md-desc hr {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.md-desc h2 {
    font-size: 2.25rem;
    margin-top: 3.25rem;
    font-weight: 400;
}

.md-desc h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.video {
    width: 100%; 
    aspect-ratio: 16/9;
}

.pdf-newtab-link:hover {
    text-decoration: underline;
}

.pdf-newtab-link {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-decoration: none;
}

.skeleton-proj-date, .skeleton-proj-title, .skel-about-title, .skel-title, .skel-txt {
    border-radius: 0.25rem;
    border: rgba(255, 255, 255, 0.2) solid 1px;
}

.skeleton-proj-title {
    margin-bottom: 1rem;
    height: 2.5rem;
    width: 20rem;
}

.skeleton-proj-date {
    height: 2rem;
    width: 11rem;
}

.skel-about-title {
    height: 4rem;
    width: 40rem;
}

.skel-title {
    height: 2.75rem;
    width: 20rem; 
    margin-top: 1.75rem;
}

.skel-txt {
    height: 1.15rem;
}

.skeleton-md-container .skel-txt:nth-child(2) {
    width: 80%;
}

.skeleton-md-container .skel-txt:nth-child(3) {
    animation-delay: 0.1s;
    width: 100%;
}

.skeleton-md-container .skel-txt:nth-child(4) {
    animation-delay: 0.2s;
    width: 95%;
}

.skeleton-md-container .skel-txt:nth-child(5) {
    animation-delay: .3s;
    width: 60%;
}

.skeleton-md-container .skel-txt:nth-child(6) {
    animation-delay: .3s;
    width: 85%;
}

.skeleton-md-container .skel-txt:nth-child(8) {
    animation-delay: .3s;
    width: 70%;
}

/* BREAK POINTS BORROWED FROM W3SCHOOLS (THANK YOU KIND SOULS OF THAT WEBSITE) */

/* Extra small devices (phones, 600px and down) */     
@media only screen and (max-width: 600px) {
    .content-container {
        width: 100vw;
        margin: 0; 
        padding: 0;
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
    }

    .video {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .desc-container {
        padding-left: 8vw;
        padding-right: 8vw;
    }

    .md-desc p, 
    .md-desc li, 
    .md-desc a {
        font-size: 1rem;
    }

    .md-desc h2 {
        font-size: 2rem;
        margin-top: 2.5rem;
    }

    .md-desc hr {
        margin-bottom: 1rem;
    }

    .proj-title, .skeleton-proj-title {
        font-size: clamp(1rem, 10vw, 3.75rem);
        margin-top: 2rem;
        margin-left: 5vw; 
        margin-right: 5vw;
    }

    .skeleton-proj-title {
        width: 75% !important;
        margin-left: 0;
        margin-right: 0;
    }

    .proj-about-title {
        font-size: clamp(1rem, 10vw, 2.75rem);
        margin-top: 2rem;
    }

    .skel-about-title {
        width: 80%;
        height: 2.75rem;
    }

    .pdf-viewer {
        width: 100%; 
        height: clamp(40px, 60vh, 700px);
    }

    .pdf-newtab-link {
        font-size: 1rem;
        margin-left: 8vw;
        margin-right: 8vw; 
        margin-top: 1rem;
    }

    .background{
        padding-bottom: 4rem;
    }

    .skel-proj-title-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    
    .skeleton-proj-title {
        height: 4rem;
        width: 20rem;
    }
    
    .skeleton-proj-date {
        height: 2rem;
        width: 10rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) {

    .content-container {
        width: clamp(200px, 75.5%, 800px);
    }

    .md-desc p, 
    .md-desc li, 
    .md-desc a {
        font-size: 1.25rem;
    }

    .background{
        padding-bottom: 6rem;
    }

    .pdf-viewer {
        width: 100%; 
        height: clamp(400px, 50vw, 800px);
    }

}
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .content-container {
        width: clamp(1000px, 60%, 1200px);
    }

    .md-desc p, 
    .md-desc li, 
    .md-desc a {
        font-size: 1.25rem;
    }

    .pdf-viewer {
        width: 100%; 
        height: clamp(400px, 80vw, 800px);
    }

    .pdf-newtab-link {
        font-size: 1.25rem;
    }

    .background{
        padding-bottom: 8rem;
    }

}